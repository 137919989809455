<template>
  <div
    class="container-fluid"
    id="about-view">
    <h1 class="text-center">About Mosaic Village</h1>
    <div class="card text-left">
      <div class="card-body">
        <p class="card-text">
          This About view is mostly a placeholder for now, experimenting
          with the Color Palette Typography sections of the Mosaic Village Branding Guide.
        </p>
        <div class="container brand-secondary-dark-blue">
          <p class="montserrat-bold-43 text-center">
            ON THE ORIGIN
          </p>
          <p class="montserrat-bold-43 text-center">
            OF SPECIES.
          </p>
          <p class="montserrat-semi-bold-16">
            By Charles Darwin, M.A.
          </p>
          <p class="cormorant-italic-14">
            Fellow Of The Royal, Geological, Linnaean, Etc., Societies;
      Author Of 'Journal Of Researches During H.M.S. Beagle's Voyage Round
      The World.'
          </p>
          <p class="cormorant-regular-10">
            When we look to the individuals of the same variety or
      sub-variety of our older cultivated plants and animals, one of the
      first points which strikes us, is, that they generally differ much
      more from each other, than do the individuals of any one species or
      variety in a state of nature. When we reflect on the vast diversity of
      the plants and animals which have been cultivated, and which have
      varied during all ages under the most different climates and
      treatment, I think we are driven to conclude that this greater
      variability is simply due to our domestic productions having been
      raised under conditions of life not so uniform as, and somewhat
      different from, those to which the parent-species have been exposed
      under nature. There is, also, I think, some probability in the view
      propounded by Andrew Knight, that this variability may be partly
      connected with excess of food. It seems pretty clear that organic
      beings must be exposed during several generations to the new
      conditions of life to cause any appreciable amount of variation; and
      that when the organisation has once begun to vary, it generally
      continues to vary for many generations. No case is on
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
div.brand-secondary-dark-blue {
  background-color: $mv_primary_orange;
  color: $mv_primary_purple;
}

.montserrat-bold-43 {
  font-family: $headings-font-family, Helvetica, Arial, serif;
  font-size: 43pt;
  font-weight: bold;
}

.montserrat-semi-bold-16 {
  font-family: $headings-font-family, Helvetica, Arial, serif;
  font-size: 16pt;
  font-weight: 600;
}

.cormorant-italic-14 {
  font-family: $font-family-serif, Helvetica, Arial, sans-serif;
  font-size: 14pt;
  font-style: italic;
}

.cormorant-regular-10 {
  font-family: $font-family-serif, Helvetica, Arial, sans-serif;
  font-size: 10pt;
}

</style>
