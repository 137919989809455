<template>
  <header id="page-header">
    <h1 class="h1">Coming Attractions</h1>
  </header>
  <div class="container py-5">
    <div class="row mb-3 justify-content-center">
      <ul class="nav nav-tabs" id="caTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about" type="button" role="tab" aria-controls="about" aria-selected="true">About</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="ran-tab" data-bs-toggle="tab" data-bs-target="#ran" type="button" role="tab" aria-controls="ran" aria-selected="false">Rapid Access Network</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="village-square-tab" data-bs-toggle="tab" data-bs-target="#village-square" type="button" role="tab" aria-controls="village-square" aria-selected="false">Village Square</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">Dashboard</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="training-tab" data-bs-toggle="tab" data-bs-target="#training" type="button" role="tab" aria-controls="training" aria-selected="false">Training</button>
        </li>
      </ul>
      <div class="tab-content" id="caTabContent">
        <!-- About Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
          <h5 class="h5 fw-bold mb-4">
            Coming soon to the Mosaic Village platform
          </h5>
          <p class="p">
            The next phase of the Mosaic Village platform will lighten your technology load by providing access to:
          </p>
          <ul>
            <li>Your Rapid Access Network to support meeting kids’ real-time, individual needs.</li>
            <li>Integrated Village Square communication tool to facilitate collaboration and continuous communication among partners.</li>
            <li>Dashboard and data collection and dashboard tools to track your initiative’s shared measures.</li>
            <li>In-depth online training series in using collective impact to address youth homelessness.</li>
          </ul>
        </div>
        <!-- Rapid Access Network Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="ran" role="tabpanel" aria-labelledby="ran-tab">
          <h5 class="h5 fw-bold mb-4">
            Rapid Access Network meets kids’ real-time needs
          </h5>
          <p class="p">
            The Rapid Access Network helps youth stay in school and off the streets by quickly meeting their specific, individual needs.
          </p>
          <img src="../../public/img/coming-attractions/mv-RAN-demo.gif" alt="Rapid Access Network informational animated gif" class="img-fluid my-2">
        </div>
        <!-- Village Square Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="village-square" role="tabpanel" aria-labelledby="village-square-tab">
          <h5 class="h5 fw-bold mb-4">
            Village Square tool supports more effective communication and collaboration
          </h5>
          <p class="p">
            The Village Square is an integrated real-time communication tool that allows members of your initiative to connect, share information and documents, ask quick questions, and stay in the loop, without adding to your email burden.
          </p>
          <p class="p">
            In addition to enabling system-wide messaging and discussion, the tool makes it easy to initiate small group and one-on-one discussions, planning and troubleshooting. Because the more members connect and support each other’s efforts, the stronger your network gets.
          </p>
          <img src="../../public/img/coming-attractions/mv-comm-tool.gif" alt="Mosaic Village communication tool animated illustration" class="img-fluid my-2">
          <p class="p">
            And as your initiative grows, you can use the Village Square to connect with other Mosaic Village communities to share successes, ask questions, and learn how others have handled challenges you’re facing.
          </p>
          <img src="../../public/img/coming-attractions/mv-comm-tool-multi-village.gif" alt="Mosaic Village multi-village communication tool animated illustration" class="img-fluid my-2">
        </div>
        <!-- Dashboard Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
          <h5 class="h5 fw-bold mb-4">
            Dashboard and data tools keep you focused on goals
          </h5>
          <p class="p">
            Shared measurement only works when you collect, manage and share data effectively. The next phase of Mosaic Village incorporates data collection and dashboard tools to help keep your partners pulling in the same direction. Tracking your impact, and communicating transparently within your initiative, helps you make better decisions, and makes it easy for members to access data for grant reporting, fundraising, and other capacity-building activities.
          </p>
          <img src="../../public/img/coming-attractions/mv-dashboard.gif" alt="Mosaic Village dashboard design preview animation" class="img-fluid my-2">
        </div>
        <!-- Training Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="training" role="tabpanel" aria-labelledby="training-tab">
          <h5 class="h5 fw-bold mb-4">
            On-demand training keeps staff and partners at the top of their game
          </h5>
          <p class="p">
            The next phase of the Mosaic Village platform will feature an integrated online training series focused on using collective impact to address youth homelessness. Moving from theory to practice, the coursework provides foundational knowledge about the collective impact approach and how it works, then offers practical, step-by-step guidance in putting its principles and practices to work in building a robust and effective initiative.
          </p>
          <p class="p">
            The training is useful in helping partners across organizations and sectors develop a shared understanding of the hows and whys of using the collective-impact-driven Mosaic Village approach, and gives staff clear instruction on how to move through the steps of building a cohesive network to support kids.
          </p>
          <img src="../../public/img/coming-attractions/mv-training.gif" alt="Mosaic Village training animation" class="img-fluid my-2">
        </div>
      </div>
    </div>
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <router-link
          tag="button"
          class="btn btn-primary float-start"
          to="/action-plan">
          <i class="bi bi-chevron-left"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DashboardPage',
};
</script>

<style lang="scss" scoped>
.nav-tabs .nav-link.active {
  color: $info;
}
</style>
